import React, { useState, useEffect } from "react";
import "./BMIcalculator.css";

const BMICalculator = () => {
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [unit, setUnit] = useState("meters"); // Default unit is meters
  const [bmi, setBMI] = useState(null);
  const [bmiCategory, setBMICategory] = useState(null);
  const [advice, setAdvice] = useState("");
  const [resultVisible, setResultVisible] = useState(false);

  const calculateBMI = () => {
    if (weight && height) {
      const weightInKg = parseFloat(weight);
      let heightInM = parseFloat(height);

      if (unit === "feet") {
        // Convert height from feet to meters if needed
        heightInM *= 0.3048;
      } else if (unit === "cm") {
        // Convert height from centimeters to meters if needed
        heightInM /= 100;
      }

      const bmiValue = (weightInKg / (heightInM * heightInM)).toFixed(2);
      setBMI(bmiValue);
      categorizeBMI(bmiValue);
      setResultVisible(true);
      clearInputs();
      setTimeout(() => {
        setResultVisible(false);
        document
          .querySelector(".result-container")
          .classList.add("hide-result");
      }, 10000); // Adjust the time (in milliseconds) as needed
    }
  };

  const clearInputs = () => {
    setWeight("");
    setHeight("");
  };

  const categorizeBMI = (bmiValue) => {
    if (bmiValue < 18.5) {
      setBMICategory("Underweight");
      setAdvice("You are underweight. Consult a healthcare professional.");
    } else if (bmiValue < 24.9) {
      setBMICategory("Normal Weight");
      setAdvice("Your BMI is in the healthy range.");
    } else if (bmiValue < 29.9) {
      setBMICategory("Overweight");
      setAdvice("You are overweight. Consider a balanced diet and exercise.");
    } else {
      setBMICategory("Obese");
      setAdvice("You are obese. Consult a healthcare professional.");
    }
  };

  return (
    <div className="bmi-calculator">
      <p className="BMIdescription"> 
        Would you like to calculate your Body Mass Index?.
      </p>
      <div className="input-fields">
        <input
          type="number"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          placeholder="Weight in (Kg)"
        />
        <div className="height-input">
          <input
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            placeholder={`Height in ${unit}`}
          />
          <select className="selection"value={unit} onChange={(e) => setUnit(e.target.value)}>
            <option value="meters">meter</option>
            <option value="feet">feet</option>
            <option value="cm">cm</option>
          </select>
        </div>
      </div>
      <button className="BMIbutton" onClick={calculateBMI}>
        Calculate BMI
      </button>
      <div className={`result-container ${resultVisible ? "show-result" : ""}`}>
        {bmi && <p className="bmi-result">Your BMI: {bmi}</p>}
        <p>Category: {bmiCategory}</p>
        <p>{advice}</p>
      </div>
    </div>
  );
};

export default BMICalculator;
