import React from "react";
import "./HighlightsPage.css";

const HL = () => {
  return (
    <div className="experience" id="experience">
      <div className="achievement">
        <div className="circle">30+</div>
        <span className="HLdescription">Years of Expertise</span>
      </div>
      <div className="achievement">
        <div className="circle">1000+</div>
        <span className="HLdescription">Success Stories</span>
      </div>
      <div className="achievement">
        <div className="circle">7+</div>
        <span className="HLdescription">Medical Services</span>
      </div>
    </div>
  );
};

export default HL;
