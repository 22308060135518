import React from "react";
import LandingPage from "../LandingPage/LandingPage";
import Intro from "../Intro/Intro";
import Highlights from "../HighlightsPage/HighlightsPage";
import DoctorsPage from "../DoctorsPage/DoctorsPage";
import WhyChooseUs from "../WhyUs/WhyChooseUs";
import ProceduresDonePage from "../ProceduresDone/ProceduresDone";
import TodaysTiming from "../TodaysTmingforEU/EUTiming";

const Home = () => {
  return (
    <>
    
      <LandingPage/>
      <Intro />
      <Highlights />
      {/* <TodaysTiming/> */}
      <DoctorsPage />
      <ProceduresDonePage />
      <WhyChooseUs/>
    </>
  );
};

export default Home;
