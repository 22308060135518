import Navbar from "./components/Navbar/Navbar";
import Intro from "./components/Intro/Intro";
import "./App.css";
import Footer from "./components/Footer/Footer";
import { useContext } from "react";
import { themeContext } from "./Context";

import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./components/Home/Home";

import AboutUs from "./components/AboutUs/AboutUs";
import Services from "./components/ServicesPage/ServicesPage";
import DoctorAppointments from "./components/DoctorAppointments/DoctorAppointments";
import ViewAppointments from "./components/ViewDoctorAppointments/ViewAppointments";
import TimingPageForAdmin from "./components/TodaysTimingforADMIN/AdminTimingPage";
import EventsAdminPage from "./components/RecentNewsAdmin/EventsAdmin";
import RecentNews from "./components/RecentNews/RecentNews";
import SuccessStoriesAdminPage from "./components/RecentNewsAdmin/SSadmin";
import OffersAdminPage from "./components/OffersAdmin/OffersAdmin";
import OffersEUpage from "./components/OffersEU/OffersEU";
import DoctorsPageDynamiForAdmin from "./components/DoctorsPageDynamic/DoctorsPageDyAdmin"
import Admin from "./components/MainAdminPage/MAP"
import DoctorDetailsForAppointmentPage from "./components/DoctorDetailsForAppointmentPage/DoctorDFAP"


function App() {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="App">
      <div className="gradient__bg">
      <BrowserRouter>
      <Navbar/>
        
        <Routes>

          <Route exact path='/' element={<Home/>} />
          <Route exact path='/AboutUs' element={<AboutUs/>} />
          <Route exact path='/Services' element={<Services/>} />
          {/* <Route exact path='/Appointments' element={<DoctorAppointments/>} /> */}
          
          <Route exact path='/View_Appointments' element={<ViewAppointments/>} />
          <Route exact path='/timing' element={<TimingPageForAdmin/>} />
          <Route exact path='/RecentNews' element={<RecentNews/>} />
          <Route exact path='/EventsAdminPage' element={<EventsAdminPage/>} />
          <Route exact path='/Success_Stores_AdminPage' element={<SuccessStoriesAdminPage/>} />
          <Route exact path='/OffersAdminPage' element={<OffersAdminPage/>} />
          <Route exact path='/Offers' element={<OffersEUpage/>} />
          <Route exact path='/DoctorsAdminPage' element={<DoctorsPageDynamiForAdmin/>} />
          <Route exact path='/Admin' element={<Admin/>} />
          <Route exact path='/DFAP' element={<DoctorDetailsForAppointmentPage/>} />
          
          
        </Routes>
        <Footer/>
      
      </BrowserRouter>

      
      </div>
    </div>
  );
}

export default App;
