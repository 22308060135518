import React from "react";
import "./LandingPage.css";
import dentists from "../../img/dentist1.png";
import YourComponent from "../CokeAnimation/CokeAnim";
import BMIcalculator from "../BMIcaculator/BMIcalculator";
import { motion } from "framer-motion";


const Intro = () => {
  // Transition
  const transition = { duration: 2, type: "spring" };
  const telephoneNumber = "+96824614272";
  const phoneNumber = "+96879899892";
  const whatsappMessage = "Hello, I would like to inquire about your services.";

  return (
    <div className="Intro" id="Intro">
      <div className="i-left">
        <div className="i-title">
          <h1>Dedicated to Your Wellness</h1>
          <p>
            In Close Proximity to the Indian School Ghubrah, Muscat, Sultanate
            of Oman. Contact us on +968 24614272
          </p>
          <div className="button-container">
  <a
    href={`https://wa.me/${phoneNumber}/?text=${encodeURIComponent(
      whatsappMessage
    )}`}
    target="_blank"
    rel="noopener noreferrer"
    className="responsive-button"
  >
    <button className="whatsapp-button">
      <i className="fab fa-whatsapp"></i> <span className="button-text">WhatsApp</span>
    </button>
  </a>

  {/* Add the responsive-button class to other buttons */}
  {/* <a href="/Appointments" className="responsive-button">
    <button className="intro-button">
      <i className="fa fa-calendar"></i> <span className="button-text">Book an Appointment</span>
    </button>
  </a> */}

  <a href={`tel:${telephoneNumber}`} className="responsive-button">
    <button className="call-button">
      <i className="fas fa-phone"></i> <span className="button-text">Call</span>
    </button>
  </a>

  <a href="https://maps.app.goo.gl/i23AmfVUuvGugdTh9?g_st=ic" className="responsive-button">
    <button className="locate-us-button">
      <i className="fas fa-map-marker-alt"></i> <span className="button-text">Locate Us</span>
    </button>
  </a>
</div>

        </div>

        <YourComponent />
        <BMIcalculator />
      </div>

      <div className="i-right">
        <img src={dentists} alt="" />
        <motion.img
          initial={{ left: "-36%" }}
          whileInView={{ left: "-24%" }}
          transition={transition}
          src={""}
          alt=""
        />
        <div className="blur" style={{ background: "rgb(228, 250, 226) " }}></div>
        <div
          className="blur"
          style={{
            // background:"rgb(250, 248, 250) ",
             background:"rgb(252, 240, 249) ",
            top: "17rem",
            width: "21rem",
            height: "11rem",
            left: "-9rem",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Intro;
