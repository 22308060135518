// UserDoctorsPage.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseconfig';
import './UserDoctorsPage.css'; // Use the same CSS file as DoctorsPage

const UserDoctorsPage = () => {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const doctorsCollection = collection(db, 'doctorsdynamic'); // Updated collection name
      const doctorsSnapshot = await getDocs(doctorsCollection);
      const doctorsData = doctorsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setDoctors(doctorsData);
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div className="doctors-page"> {/* Use the same class name as DoctorsPage */}

        {/* blur component */}
        <div className="blur" style={{ background: "rgb(238 210 255)" }}></div>
        <div
          className="blurdoc"
          style={{
            background: "#C1F5FF",
            top: "21rem",
            width: "21rem",
            height: "11rem",
            left: "-9rem",
          }}
        ></div>
        
      <header>
        <h1 className='titleDoc'>Our Doctors</h1>
      </header>
      <section >
        {loading ? (
          <p>Loading...</p>
        ) : (
          <ul className="UDL">
            {doctors.map((doctor) => (
              <li key={doctor.id}>
                <div className="doctor animate-from-left in-view"> {/* Use the same class names */}
                  <img src={doctor.image} alt={doctor.name} />
                  <div className="doctor-details"> {/* Use the same class name */}
                    <h2 className="doctor-name">{doctor.name}</h2>
                    <p className="doctor-specialty">{doctor.specialty}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </section>
    </div>
  );
};

export default UserDoctorsPage;
