import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">

      <div className="footer-container">
        <div className="footer-logo">
          <a href="/">
          <img src="/GMCPfooterLogo.jpeg" alt="Ghala Medical Clino" />
          </a>
          <h2></h2>
        </div>
        <div className="footer-contact">
          <h3>Contact Us</h3>
          <p>P.O Box: 611. Postal code: 111 </p>
          <p> Opposite Indian School - Al Ghubra </p>
          <p>North Ghobra, Muscat, Sultanate of Oman</p>
          <p>
            Phone: <a href="tel:+96824614272">(+968)24614272</a>
          </p>
          <p>
            Email:{" "}
            <a href="mailto:ghalaclinic@gmail.com">ghalaclinic@gmail.com</a>
          </p>
        </div>
        <div className="footer-social">
          <h3>Follow Us</h3>
          <ul>
            <li>
              <a href="#" className="social-link">
                Facebook
              </a>
            </li>
            <li>
              <a href="#" className="social-link">
                Twitter
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/ghalaclinic?igshid=OGQ5ZDc2ODk2ZA==" className="social-link">
                Instagram
              </a>
            </li>
            <li>
              <a href="/Admin" className="social-link ">
                Login
              </a>
            </li>

            {/* <li>
              <a href="#" className="social-link ">
                Back to Top
              </a>
            </li> */}
            
          </ul>
        </div>

        <div className="footer-social">
          <h3>Useful Links</h3>
          <ul>
            
            <li>
              <a href="https://qms-ghalamedicalclinic.netlify.app/admin" className="social-link ">
                QMS Admin Login
              </a>
            </li>

            <li>
              <a href="https://qms-ghalamedicalclinic.netlify.app/4d" className="social-link ">
                QMS Doctor Login
              </a>
            </li>

            <li>
              <a href="https://qms-ghalamedicalclinic.netlify.app/spwu" className="social-link ">
               Generate Token Request
              </a>
            </li>

            <li>
              <a href="#" className="social-link ">
                Back to Top
              </a>
            </li>
            
          </ul>
        </div>

        
      </div>
    </footer>
  );
};

export default Footer;
