import React, { useEffect, useState } from "react";
import "./WhyChooseUs.css";

function WhyUs() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    function checkVisibility() {
      const container = document.querySelector(".container");
      const rect = container.getBoundingClientRect();
      const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
      setIsVisible(isVisible);
    }

    window.addEventListener("scroll", checkVisibility);
    checkVisibility();

    return () => {
      window.removeEventListener("scroll", checkVisibility);
    };
  }, []);

  return (
    <div className={`why-choose-us ${isVisible ? "visible" : ""}`}>

          {/* blur component */}
          <div className="blur" style={{ background: "rgb(238 210 255)" }}></div>
        <div
          className="blur"
          style={{
            background: "#C1F5FF",
            top: "17rem",
            width: "21rem",
            height: "11rem",
            left: "-9rem",
          }}
        ></div>
      <div className="container">
        <h1 className="WhyTitle">Why Choose Us?</h1>
        <p>
          Ghala Medical Clinic operates to meet the primary health needs of
          residents of Muscat and the surrounding communities. The clinic
          utilizes a patient centered medical care approach that seeks to
          address the patient s all medical needs including physical and
          psychological issues. Although we use the latest appropriate
          technology available, we make sure to never forsake the physical care
          given by the health care staff. Our goal is to help patients to manage
          disease and to encourage patients and families to adopt healthy
          lifestyle habits that will reduce the risk of disease and help them
          function at an optimal level of wellness. All the services available
          here are designed to be affordable to all sections of the society.
          Ghala clinic gives utmost care to have a safe clinic environment for
          the patient, accompanying persons and the staff.
        </p>
        <a href="https://youtu.be/Kndp7Ow6Qdg?si=WamThDHBcPMg0F2S" target="_blank" rel="noopener noreferrer">
  <button className="BMIbutton">
    Watch Video
  </button>
</a>
      </div>
    </div>
  );
}

export default WhyUs;
