import React from "react";
import "./CokeAnim.css";
import SpecialOffer from "../../img/SpecialOffer.png";
import ZoomOutImage from "../../img/Steth.png";

const YourComponent = () => {
  return (
    <div className="card">
      <div className="circle">
        <h3 className="logo">Is your birthday Today? <span className="highlight"> click here</span> to view our offer for you</h3>
      </div>
      <div className="content">
        <h3 className="title21">Birthday Offer</h3>
        <p>
        Celebrate your birthday with a gift of health: Enjoy a free consultation at Ghala Medical Clinic!
        </p>
        <a href="/Offers">Explore More Offers</a>
      </div>
      <img src={ZoomOutImage} className="product_img" alt="Product" />
    </div>
  );
};

export default YourComponent;
