import React from "react";
import "./Intro.css";
import "font-awesome/css/font-awesome.min.css";

const IntroductionPage = () => {
  // Default phone number and WhatsApp message
  const telephoneNumber = "+96824614272";
  const phoneNumber = "+96871198835";
  const whatsappMessage = "Hello, I would like to inquire about your services.";

  return (
    <div className="introduction-container">
      <div className="introduction-content">
        <h3 className="intro-title">Doctor Availability and Pharmacy Hours</h3>

        <a href="https://whatsapp.com/channel/0029VaC6HLMAInPh1tBXX332">
          <button className="search-button">
            <i className="fas fa-search"></i> Search Availability
          </button>
        </a>

        {/* blur component */}
        <div className="blur" style={{ background: "rgb(238 210 255)" }}></div>
        <div
          className="blur"
          style={{
            background: "#C1F5FF",
            top: "17rem",
            width: "21rem",
            height: "11rem",
            left: "-9rem",
          }}
        ></div>

        <p className="intro-description">
        Schedule your next appointment with ease, making a proactive choice to prioritize your health and well-being.
        </p>

        <div className="button-container">
          {/* <a
            href={`https://wa.me/${phoneNumber}/?text=${encodeURIComponent(
              whatsappMessage
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="whatsapp-button">
              <i className="fab fa-whatsapp"></i> WhatsApp
            </button>
          </a> */}

          <a href="https://qms-ghalamedicalclinic.netlify.app/spwu">
            <button className="intro-button">
              <i className="fa fa-calendar"></i> Generate Token Request for Consultation
            </button>
          </a>

          {/* <a href={`tel:${telephoneNumber}`}>
            <button className="call-button">
              <i className="fas fa-phone"></i> Call
            </button>
          </a>

          <a href="https://maps.app.goo.gl/i23AmfVUuvGugdTh9?g_st=ic">
            <button className="locate-us-button">
              <i className="fas fa-map-marker-alt"></i> Locate Us
            </button>
          </a> */}
        </div>
      </div>

      
    </div>
  );
};

export default IntroductionPage;
